.Section__container___3YYTG {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 12px -6px rgba(0, 0, 0, 0.3);
  font-size: 1rem;
  margin: 15vh auto;
  padding: 18px;
  width: 400px;
}

.SpreadsheetGridScrollWrapper_scrollable {
  overflow-y: visible;
  min-height: 100vh;
}
.note {
  border: 1px solid #cccccc;
  margin: 20px auto;
  padding: 20px;
}
.note p {
  font-size: 15px;
}
.popover-window {
  max-height: 200px;
  overflow: auto;
}
.note li {
  display: inline-block;
  margin-right: 8px;
  font-size: 10px;
}

.Form__formContainer___1GA3x {
  height: 100%;
  width: 100%;
}

.Form__formSection___1PPvW {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
}

.Form__formSection___1PPvW form {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.Section__sectionBody___ihqqd:first-child div {
  margin: 4px 0;
}

.Section__sectionBody___ihqqd input {
  border: 1px solid lightgray;
  border-radius: px;
  height: 36px;
  padding-left: 12px;
  width: 100%;
}

.Hint__hint___2XngB {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.slds-context-bar {
  margin-top: 50px !important;
}
.slds-timeline__item_expandable:before {
  left: 0.25rem !important;
}
.SpreadsheetGrid__headCell {
  background: #fff !important;
  color: #000 !important;
}

.react-kanban-board {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.react-kanban-board .card {
  min-width: 240px;
  width: 100%;
}
[data-rbd-drag-handle-context-id="1"] > div {
  display: block !important;
}
.react-kanban-board > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.react-kanban-column {
  flex-basis: 100%;
}
.stage-header {
  background: var(--lwc-brandAccessible, rgb(0, 112, 210));
  padding: 4px 0px;
}
.stage-header h2 {
  color: #fff;
  font-size: 16px;
  margin: 0px 0px 0px;
  text-align: center;
}
.stage-header h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin: 0px;
  text-align: center;
}
.slds-box {
  background: #fff;
}

.slds-notify_toast {
  bottom: 20px;
  position: fixed;
  right: 0px;
}

.stage__title {
  background: var(--lwc-brandAccessible, rgb(0, 112, 210));
  color: #fff;
  margin: 0px;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: center;
}
.slds-stages .stage {
  border-right: 1px solid #ccc;
  min-height: 80vh;
}

.stage__total {
  color: green;
  font-size: 2rem;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.slds-input[readonly] {
  border-color: #dddbda !important;
}

.grid-photo {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}
