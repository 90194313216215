.report-table {
  border-collapse: collapse;
  border: 1px solid gray;
}

.report-table > thead > th {
  background-color: #189dd8;
  text-align: center;
  padding: 4px;
  color: #ffffff;
  font-weight: 900;
}

.report-table td {
  text-align: center;
  padding: 4px;
}

.report-page {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  flex: 1;
  width: 1000px;
  height: 1414px;
  background-color: #ffffff;
  margin: 16px;
}

.report-page h1 {
  color: #189dd8;
  font-size: 32px;
  text-transform: uppercase;
}

.report-page .col {
  display: flex;
  flex-direction: column;
}

.report-page ul > li {
  list-style-type: disc;
}

.report-page .blue {
  color: #189dd8;
}
