.bba-data-table {
  border-collapse: separate;
  border-top: 1px solid #dddbda;
  border-bottom: 1px solid #dddbda;
  background-color: #fff;
  font-size: inherit;
  width: 100%;
  border-spacing: 0;
  box-sizing: border-box;
  thead {
    th {
      background-color: #fafaf9;
      color: #514f4d;
      padding: 0.25rem 0.5rem;
      font-weight: 700;
      line-height: normal;
    }
  }

  tr {
    line-height: 1;
  }

  td {
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
    position: relative;
    border-top: 1px solid #dddbda;
  }
}

.text-formatting::first-letter {
  text-transform: capitalize;
}
