.slds-global-actions {
  display: flex;
  flex-direction: row-reverse;
}

.sign-out-btn {
  width: 6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  vertical-align: middle;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #dddbda;
  -webkit-transition: border 0.15s linear;
  transition: border 0.15s linear;
  border-color: #0070d2;
  background-color: white;
  color: #0070d2;
}

.btn-close {
  /* border: 2px solid red; */
}
.header-custom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn-image-remove {
  margin-top: 10px;
  background-color: #f30707;
  border: 0;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 11px;
}
